import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

window.addEventListener('DOMContentLoaded', () => {
  new Swiper('.swiper-container', {
    // ここからオプション
    loop: true,
    pagination: {
      el: '.swiper-pagination',
    },

    scrollbar: {
      el: '.swiper-scrollbar',
    },
  });
});
