import $ from 'jquery';

/*  スマホアコーディオンメニュー
-------------------------------------------------------*/

$(function () {
  if (window.matchMedia('screen and (max-width:750px)').matches) {
    $('.is-parent span').click(function () {
      $(this).toggleClass('is-open');
      $(this).next('.subNavList').slideToggle();
      $('.is-parent').not($(this)).removeClass('is-open');
      $('.is-parent').not($(this)).next('.subNavList').slideUp();
    });
  } else {
    $('.is-parent').hover(function () {
      $(this).toggleClass('is-open');
      $(this).children('.subNavList').slideToggle();
      $('.is-parent').not($(this)).removeClass('is-open');
      $('.is-parent').not($(this)).next('.subNavList').slideUp();
    });
  }
});

$(function () {
  $('.is-parent2').click(function () {
    $(this).toggleClass('is-open');
    $('.is-parent2').not($(this)).removeClass('is-open');
    $('.is-parent2').not($(this))('.subNavList').slideUp();
  });
});
/*  トグルメニュー
  -------------------------------------------------------*/

$(function () {
  $('.js-toggle-btn').click(function () {
    $(this).toggleClass('is-active');
    $('.js-spNav').toggleClass('is-open');
    $('.js-spNav').slideToggle();
  });
});

/*  カテゴリトグルメニュー
  -------------------------------------------------------*/
$(function () {
  $('.toggle-btn').click(function () {
    $(this).toggleClass('is-open');
    $('.toggle-arrow').toggleClass('is-open');
    $('.toggle-content').toggleClass('is-open');
  });
});

/*  会員メニュー
  -------------------------------------------------------*/

$(function () {
  $('.js-memberBtn').click(function () {
    $('.subMmemberArea').toggleClass('is-active');
  });
});

/* スムーススクロール
  ------------------------------------- */

$(function () {
  var urlHash = location.hash;
  var animeSpeed = 500;
  if (urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function () {
      var target = $(urlHash);
      var position = target.offset().top;
      $('body,html').stop().animate({ scrollTop: position }, animeSpeed);
    }, 100);
  }
  $('a[href^="#"]').on({
    click: function () {
      var href = $(this).attr('href');
      var target = $(href);
      var position = target.offset().top;
      $('body,html').stop().animate({ scrollTop: position }, animeSpeed);
    },
  });
});

//タブ
$(function () {
  $('.tab01__cards:not(:first-of-type)').css('display', 'none');
  $('.tab01__btns .btn').click(function () {
    // タブメニューをhoverしたら
    var index = $('.tab01__btns .btn').index(this); // hoverしたタブ番号を取得
    $('.tab01__btns .btn').removeClass('is-active'); // タブ現在地クラスを削除し、
    $(this).addClass('is-active'); // hoverしたタブにタブ現在地クラスを付与
    //////////// コンテンツの制御
    $('.tab01__cards').hide().eq(index).show(); // hoverしてないコンテンツは非表示、hoverした番号は表示
  });
});

//カラムタブ
$(function () {
  $('.tab-column__btns .btn').click(function () {
    // タブメニューをhoverしたら
    var target = $(this).data('tab'); // hoverしたタブ番号を取得
    $('.tab-column__btns .btn').removeClass('is-active'); // タブ現在地クラスを削除し、
    $(this).addClass('is-active'); // hoverしたタブにタブ現在地クラスを付与

    $('.page-tab-content > *').hide();
    $('#' + target).show();
  });
});


$(function () {
  const authorDOM = $('.lang .lang__tab .author');
  const wrapDOM = $('.lang .lang__wrap');
  authorDOM.click(function () {
    authorDOM.removeClass('is-current');
    $(this).addClass('is-current');
    const targetLang = $(this).data('lang');

    if (targetLang === 'chinese') {
      wrapDOM.find('.is-ch').show();
      wrapDOM.find('.is-jp').hide();
    } else {
      wrapDOM.find('.is-ch').hide();
      wrapDOM.find('.is-jp').show();
    }
  });

  const langModalTriggerDOM = $('.lang .lang__change');
  const langModalDOM = $('.langModal');
  const langModalCloseDOM = $('.langModal__close');

  langModalTriggerDOM.click(function () {
    const parent = $(this).parents('.lang__wrap');
    const chineseHTML = parent.find('.is-ch .lang__txt').html();
    const japaneseHTML = parent.find('.is-jp .lang__txt').html();
    langModalDOM.find('.langModal__ch .langModal__txt').html(chineseHTML);
    langModalDOM.find('.langModal__jp .langModal__txt').html(japaneseHTML);
    langModalDOM.fadeIn();
  });

  langModalCloseDOM.click(function () {
    langModalDOM.fadeOut();
  });
});

$(function () {
  const categoryTriggerDOM = $('.categoryTable .categoryTable__th');
  const categoryContentTargetDOM = $('.categoryTable .table01__tr');
  categoryTriggerDOM.click(function () {
    categoryTriggerDOM.removeClass('is-current');
    $(this).addClass('is-current');
    categoryContentTargetDOM.hide();
    categoryContentTargetDOM.eq($(this).index()).show();
  })
});
