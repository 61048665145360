import Mark from 'mark.js';

const copyToClipboard = (containerid) => {
  if (document.selection) {
    var range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerid));
    range.select().createTextRange();
    document.execCommand("copy");
  } else if (window.getSelection) {
    const range = document.createRange();
    const selection = window.getSelection()
    range.selectNode(document.getElementById(containerid));
    selection.removeAllRanges(); // Remove all ranges from the selection.
    selection.addRange(range);
    document.execCommand("copy");
    selection.removeAllRanges();
  }
}

function preventCopy(selector) {
  if(!selector) {
    selector = 'body'
  }

  $(selector).bind('cut copy', function(e) {
    e.preventDefault();
  });

  $(selector).on('contextmenu', function(e) {
    return false;
  });

  $(selector).attr('onmousedown', 'return false');
  $(selector).attr('onselectstart', 'return false');
}

function markText(container, keyword) {
  var markObject = new Mark(container);
  markObject.mark(keyword);
}

function markRegex(container, regex) {
  var markObject = new Mark(container);
  markObject.markRegExp(regex);
}

window.utils = {
  copyToClipboard: copyToClipboard,
  preventCopy: preventCopy,
  markText: markText,
  markRegex: markRegex
}
