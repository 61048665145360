// include Javascript
import Rails from '@rails/ujs';
import './common';
import './swipe';
import './utils';

// Start Rails_ujs
Rails.start()

// include stylesheet
import 'stylesheets/site/application.scss'
import 'stylesheets/site/print.scss'
